import React from "react";
import PropTypes from "prop-types";
import PG_RP_REVIEW_SMILEY_CONTENT from "./index.content";
import ReviewEmail from "./ReviewEmail";
import ReviewTextField from "./ReviewTextField";
import ReviewThanks from "./ReviewThanks";
import "./index.scss";

const Review = ({
  reviewText,
  handlerReviewText,
  email,
  handlerEmail,
  countStars,
  disabledButton,
  handlerSubmit,
}) => {
  return (
    <section className="PgRP-Review">
      <div className="container">
        <div className="PgRP-Review-wrap">
          <img
            src={PG_RP_REVIEW_SMILEY_CONTENT[countStars]}
            className="PgRP-Review__Smiley"
            alt="Smiley"
          />
          {countStars < 3 ? (
            <ReviewTextField
              handlerReviewText={handlerReviewText}
              reviewText={reviewText}
            />
          ) : (
            <ReviewThanks />
          )}
          <ReviewEmail
            email={email}
            handlerEmail={handlerEmail}
            secondState={countStars > 2}
          />
          <button
            className="PgRP-Review__submit"
            disabled={disabledButton}
            onClick={handlerSubmit}
          >
            {countStars < 3 ? "SUBMIT A REVIEW" : "GO TO TRUSTPILOT"}
          </button>
        </div>
      </div>
    </section>
  );
};
Review.propTypes = {
  email: PropTypes.string,
  reviewText: PropTypes.string,
  countStars: PropTypes.number.isRequired,
  disabledButton: PropTypes.bool.isRequired,
  handlerSubmit: PropTypes.func.isRequired,
  handlerEmail: PropTypes.func.isRequired,
  handlerReviewText: PropTypes.func.isRequired,
};
export default Review;
