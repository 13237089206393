import submitReviewModel from "../../models/review-page/submitReviewModel";
import track from "../../tracking/analytics";

class ReviewController {
  constructor() {}

  followLink(action, link) {
    track("LANDING|Review", action, link, null, "LANDING");
    window.open(link, "_self");
  }

  successSubmit(countStars, updateIsLoadingState, updateIsOpenedModalState) {
    updateIsLoadingState(false);
    countStars < 3
      ? updateIsOpenedModalState(true)
      : this.followLink(
          "GO TO TRUSTPILOT",
          "https://www.trustpilot.com/evaluate/amzscout.net"
        );
  }

  errorSubmit(updateIsLoadingState) {
    updateIsLoadingState(false);
    alert("Please try again");
  }

  submit(countStars, result, updateIsLoadingState, updateIsOpenedModalState) {
    updateIsLoadingState(true);
    submitReviewModel(result)
      .then(() =>
        this.successSubmit(
          countStars,
          updateIsLoadingState,
          updateIsOpenedModalState
        )
      )
      .catch(() => {
        this.errorSubmit(updateIsLoadingState);
      });
  }
}

export default ReviewController;
