import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import RatingSelectionContainer from "../../../containers/review-page/RatingSelectionContainer";
import "./index.scss";

const Rating = ({
  countStars,
  showStar,
  disabledStarCount,
  handlerClickRating,
  evaluationText,
}) => {
  return (
    <section
      className={getTrueClasses(
        "PgRP-Rating",
        disabledStarCount
          ? "PgRP-Rating_disabledStarCount"
          : "PgRP-Rating_heightResponsive"
      )}
    >
      <div className="container">
        <RatingSelectionContainer
          countStars={countStars}
          showStar={showStar}
          handlerClickRating={handlerClickRating}
          disabledStarCount={disabledStarCount}
          evaluationText={evaluationText}
        />
      </div>
    </section>
  );
};
Rating.propTypes = {
  countStars: PropTypes.number.isRequired,
  showStar: PropTypes.func.isRequired,
  disabledStarCount: PropTypes.bool.isRequired,
  handlerClickRating: PropTypes.func.isRequired,
  evaluationText: PropTypes.string,
};

export default Rating;
