import React from "react";
import OgImg from "../assets/images/raster/pages/amazon-super-urls/common/mainscreen_super_url_tool.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/review-page/HomeIntro";
import Request from "../components/review-page/Request";
import ReviewContainer from "../containers/review-page/ReviewContainer";
import RatingContainer from "../containers/review-page/RatingContainer";
import RootProvider from "../store/providers/ReduxProvider";
import ModalContainer from "../containers/review-page/ModalContainer";
import Loader from "../components/review-page/Loader";

const PgRP = () => {
  return (
    <RootProvider>
      <Layout customClass="PgRP">
        <Seo
          title="AMZScout Reviews: Rate and Provide Feedback"
          page="review-page"
          manifest="browserconfig.xml"
          ogImg={OgImg}
          description=""
        />
        <HomeIntro />
        <Request />
        <RatingContainer />
        <ReviewContainer />
        <ModalContainer />
        <Loader />
      </Layout>
    </RootProvider>
  );
};

export default PgRP;
