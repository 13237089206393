import React from "react";
import "./index.scss";

const Request = () => {
  return (
    <section className="PgRP-Request">
      <div className="container">
        <div className="PgRP-Request__info">
          <h2 className="PgRP-Request__title">We are glad to see you here!</h2>
          <div className="PgRP-Request__subTitle">
            <p>
              The AMZScout team appreciates and takes into account our users’
              opinions in order to help us improve our tools and services.
            </p>
            <p>
              Please rate, on a scale of one to five, how satisfied you are with
              the quality and efficiency of AMZScout's services.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Request;
