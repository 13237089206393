import React from "react";
import PropTypes from "prop-types";
import SVGClose from "../../../assets/images/vector/components/common/closeInit.svg";
import "./index.scss";

const Modal = ({ handlerClose }) => {
  return (
    <div className="PgRP-Modal">
      <div className="PgRP-Modal__wrapper">
        <div className="PgRP-Modal__card">
          <div className="PgRP-Modal__close" onClick={() => handlerClose()}>
            <img src={SVGClose} alt="X" />
          </div>
          <p className="PgRP-Modal__text">Thank you for your feedback!</p>
        </div>
      </div>
    </div>
  );
};
Modal.propTypes = {
  handlerClose: PropTypes.func.isRequired,
};
export default Modal;
