import React, { useEffect } from "react";
import Review from "../../components/review-page/Review";
import { useSelector, useDispatch } from "react-redux";
import { reviewActions } from "../../store/slice/review-slice";
import ReviewController from "../../controllers/review-page/reviewController";

const reviewController =
  typeof window !== "undefined" && new ReviewController();

const ReviewContainer = () => {
  const { countStars, reviewText, disabledButton, email, disabledStarCount } =
    useSelector((state) => state.review);
  const dispatch = useDispatch();

  const updateReviewTextState = (val) => {
    dispatch(reviewActions.updateReviewTextState(val));
  };
  const updateEmailState = (val) => {
    dispatch(reviewActions.updateEmailState(val));
  };
  const updateDisabledButtonState = (val) => {
    dispatch(reviewActions.updateDisabledButtonState(!val));
  };
  const updateIsOpenedModalState = (val) => {
    dispatch(reviewActions.updateIsOpenedModalState(val));
  };
  const updateIsLoadingState = (val) => {
    dispatch(reviewActions.updateIsLoadingState(val));
  };
  const handlerReviewText = (text) => {
    updateReviewTextState(text);
  };

  const handlerEmail = (email) => {
    updateEmailState(email);
  };
  const checkDisabledButton = () => {
    updateDisabledButtonState(!!reviewText || countStars > 2);
  };

  const normalization = (countStars, reviewText, email) => {
    return {
      countStars: countStars + 1,
      reviewText: reviewText.trim(),
      email: email.trim(),
    };
  };
  const handlerSubmit = () => {
    reviewController.submit(
      countStars,
      normalization(countStars, reviewText, email),
      updateIsLoadingState,
      updateIsOpenedModalState
    );
  };

  useEffect(() => {
    checkDisabledButton();
  }, [countStars, reviewText]);

  if (!disabledStarCount) {
    return null;
  }

  return (
    <Review
      email={email}
      reviewText={reviewText}
      countStars={countStars}
      disabledButton={disabledButton}
      handlerSubmit={handlerSubmit}
      handlerEmail={handlerEmail}
      handlerReviewText={handlerReviewText}
    />
  );
};

export default ReviewContainer;
