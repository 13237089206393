import React from "react";
import "./index.scss";

const ReviewThanks = () => {
  return (
    <div className="PgRP-Review__Thanks PgRP-Review-Thanks">
      <p className="PgRP-Review-Thanks__title">Thank you for your score!</p>
      <p className="PgRP-Review-Thanks__subTitle">
        We’d be so grateful if you would be willing to leave us a positive
        review on Trustpilot.
      </p>
    </div>
  );
};

export default ReviewThanks;
