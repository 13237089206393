import axios from "axios";

const submitReviewModel = (result) => {
  return axios({
    method: "post",
    url:
      process.env.API_HOST +
      "/v1/gsheets/review-page?sheetId=1hKLdiSj0Vlxjo3kcvydQAvPgL_K7DiSNm_ByggCm-W8",
    data: result,
  });
};

export default submitReviewModel;
