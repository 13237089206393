import React from "react"

const PG_RP_RATING_CONTENT = [
  "Very bad",
  "Bad",
  "Satisfactory",
  "Good",
  "Excellent",
];
export default PG_RP_RATING_CONTENT
