import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ReviewTextField = ({ reviewText, handlerReviewText }) => {
  return (
    <div className="PgRP-Review__TextField PgRP-Review-TextField">
      <p className="PgRP-Review-TextField__label">
        Please, share what went wrong…
      </p>
      <textarea
        className="PgRP-Review-TextField__textarea"
        placeholder="Enter your comment here"
        value={reviewText}
        onChange={(e) => handlerReviewText(e.target.value)}
        maxLength={600}
      />
    </div>
  );
};
ReviewTextField.propTypes = {
  reviewText: PropTypes.string,
  handlerReviewText: PropTypes.func.isRequired,
};

export default ReviewTextField;
