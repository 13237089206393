import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const ReviewEmail = ({ email, handlerEmail, secondState }) => {
  const textLabel = secondState
    ? "Please, write your email (optional)"
    : "Write your email (optional)";
  return (
    <div
      className={getTrueClasses(
        "PgRP-Review-Email",
        secondState && "PgRP-Review-Email_secondState"
      )}
    >
      <p className="PgRP-Review-Email__label">{textLabel}</p>
      <input
        type="email"
        value={email}
        placeholder="Enter your email here"
        className="PgRP-Review-Email__input"
        onChange={(e) => handlerEmail(e.target.value)}
      />
    </div>
  );
};
ReviewEmail.propTypes = {
  email: PropTypes.string,
  secondState: PropTypes.bool.isRequired,
  handlerEmail: PropTypes.func.isRequired,
};

export default ReviewEmail;
