import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const RatingSelection = ({ rating, evaluationText, disabledStarCount }) => {
  return (
    <div className="PgRP-Rating-Selection">
      {rating}
      <div
        className={getTrueClasses(
          "PgRP-Rating-Selection__text",
          disabledStarCount && "PgRP-Rating-Selection__text_show"
        )}
      >
        {evaluationText ?? null}
      </div>
    </div>
  );
};
RatingSelection.propTypes = {
  rating: PropTypes.array.isRequired,
  evaluationText: PropTypes.string,
  disabledStarCount: PropTypes.bool.isRequired,
};

export default RatingSelection;
