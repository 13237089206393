import React from "react";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import { useSelector } from "react-redux";
import "./index.scss";

const Loader = () => {
  const { isLoading } = useSelector((state) => state.review);
  if (!isLoading) {
    return null;
  }
  return (
    isLoading && (
      <div className="PgRP-Loader">
        <img src={GIFLoader} alt="loader" />
      </div>
    )
  );
};

export default Loader;
