import React from "react";
import Rating from "../../components/review-page/Rating";
import { useSelector, useDispatch } from "react-redux";
import { reviewActions } from "../../store/slice/review-slice";
import PG_RP_RATING_CONTENT from "../../components/review-page/Rating/index.content";

const RatingContainer = () => {
  const { countStars, disabledStarCount, evaluationText } = useSelector(
    (state) => state.review
  );
  const dispatch = useDispatch();

  const updateCountStarsState = (val) => {
    dispatch(reviewActions.updateCountStarsState(val));
  };
  const updateDisabledStarCountState = (val) => {
    dispatch(reviewActions.updateDisabledStarCountState(val));
  };
  const updateEvaluationTextState = (val) => {
    dispatch(reviewActions.updateEvaluationTextState(val));
  };

  const showStar = (i) => {
    if (!updateCountStarsState) return;
    updateCountStarsState(i);
    disabledStarCount && updateEvaluationTextState(PG_RP_RATING_CONTENT[i]);
  };
  const handlerClickRating = (i) => {
    updateCountStarsState(i);
    updateEvaluationTextState(PG_RP_RATING_CONTENT[i]);
    updateDisabledStarCountState(true);
  };

  return (
    <Rating
      countStars={countStars}
      showStar={showStar}
      disabledStarCount={disabledStarCount}
      handlerClickRating={handlerClickRating}
      evaluationText={evaluationText}
    />
  );
};

export default RatingContainer;
