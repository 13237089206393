import React from "react"
import SVGSmileyVeryBad from "../../../assets/images/vector/pages/review-page/Form/SmileyVeryBad.svg";
import SVGSmileyBad from "../../../assets/images/vector/pages/review-page/Form/SmileyBad.svg";
import SVGSmileySatisfactory from "../../../assets/images/vector/pages/review-page/Form/SmileySatisfactory.svg";
import SVGSmileyGood from "../../../assets/images/vector/pages/review-page/Form/SmileyGood.svg";
import SVGSmileyExcellent from "../../../assets/images/vector/pages/review-page/Form/SmileyExcellent.svg";

const PG_RP_REVIEW_SMILEY_CONTENT = [
  SVGSmileyVeryBad,
  SVGSmileyBad,
  SVGSmileySatisfactory,
  SVGSmileyGood,
  SVGSmileyExcellent,
];
export default PG_RP_REVIEW_SMILEY_CONTENT
