import React, { useEffect } from "react";
import Modal from "../../components/review-page/Modal";
import { useSelector } from "react-redux";
import ReviewController from "../../controllers/review-page/reviewController";

const reviewController =
  typeof window !== "undefined" && new ReviewController();

const ModalContainer = () => {
  const { isOpenedModal } = useSelector((state) => state.review);

  const handlerClose = () => {
    reviewController.followLink("Close", "/");
  };

  useEffect(() => {
    const htmlBlock = document.getElementsByTagName("html")[0];
    if (!isOpenedModal) {
      htmlBlock.style.overflow = "auto";
    } else {
      htmlBlock.style.overflow = "hidden";
      setTimeout(handlerClose, 2000);
    }
  }, [isOpenedModal]);

  if (!isOpenedModal) {
    return null;
  }

  return <Modal handlerClose={handlerClose} />;
};

export default ModalContainer;
