import React from "react";
import SVGGroup from "../../../assets/images/vector/pages/review-page/HomeIntro/PgRP-HomeIntro-Group.svg";
import "./index.scss";

const HomeIntro = () => {
  return (
    <section className="PgRP-HomeIntro">
      <div className="container">
        <div className="PgRP-HomeIntro__info">
          <img className="PgRP-HomeIntro__img" src={SVGGroup} alt="img" />
          <h1 className="PgRP-HomeIntro__title">
            Rate AMZScout’s <span>Tools and Services</span>
          </h1>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
