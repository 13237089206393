import React from "react";
import PropTypes from "prop-types";
import SVGFilledStar from "../../assets/images/vector/pages/review-page/Rating/FilledStar.svg";
import SVGEmptyStar from "../../assets/images/vector/pages/review-page/Rating/EmptyStar.svg";
import RatingSelection from "../../components/review-page/Rating/RatingSelection";

const RatingSelectionContainer = ({
  countStars,
  showStar,
  handlerClickRating,
  evaluationText,
  disabledStarCount,
}) => {
  const rating = [];
  for (let i = 0; i < 5; i++) {
    rating.push(
      <div
        key={i}
        onClick={() => handlerClickRating(i)}
        onMouseEnter={() => showStar(i)}
        className="PgRP-Rating-Selection__star"
      >
        <img
          id={i}
          src={i > countStars ? SVGFilledStar : SVGEmptyStar}
          alt={`${i + 1}`}
        />
      </div>
    );
  }
  return (
    <RatingSelection
      rating={rating}
      evaluationText={evaluationText}
      disabledStarCount={disabledStarCount}
    />
  );
};
RatingSelectionContainer.propTypes = {
  countStars: PropTypes.number.isRequired,
  showStar: PropTypes.func.isRequired,
  disabledStarCount: PropTypes.bool.isRequired,
  handlerClickRating: PropTypes.func.isRequired,
  evaluationText: PropTypes.string,
};

export default RatingSelectionContainer;
